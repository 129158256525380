<template>
  <div>
    <v-autocomplete
      v-model="filters.id_warehouse"
      :items="warehouses"
      :label="$t('labels.warehouse')"
      dense
      outlined
      clearable
      hide-details
      @change="onUpdate"
    ></v-autocomplete>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "PickupStep1",
  props: {
    selected: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    warehouses: [],
    filters: {},
  }),
  created() {
    this.filters = { ...this.selected };
  },
  methods: {
    async getWarehouses() {
      const { data } = await httpClient.post("/warehouse/v1/my-warehouse");
      this.warehouses = data.map((e) => ({
        value: e.id,
        text: e.code_name || "",
      }));
      if (this.warehouses.length === 1) {
        this.filters.id_warehouse =
          (this.warehouses[0] && this.warehouses[0].value) || null;
        this.onUpdate();
      }
    },
    onUpdate() {
      this.$emit("onUpdate", this.filters);
    },
  },
  mounted() {
    this.getWarehouses();
  },
};
</script>

<style scoped></style>
